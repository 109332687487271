import { Maybe } from "monet";
import { PaymentCreationFormAction, PaymentCreationFormActionType, initialPaymentCreationFormState } from ".";
import { PaymentCreationFormState } from "./entities";

export const paymentCreationFormReducer = (state: PaymentCreationFormState = initialPaymentCreationFormState, action: PaymentCreationFormAction): PaymentCreationFormState => {
    switch (action.type) {
        case PaymentCreationFormActionType.CREATE_PAYMENT_IN_PROGRESS:
            return {
                ...state,
                inProgress: true,
                error: Maybe.None(),
            };
        case PaymentCreationFormActionType.CREATE_PAYMENT_SUCCESS:
            return {
                ...state,
                inProgress: false,
                error: Maybe.None(),
                success: Maybe.Some(action.payment),
            };
        case PaymentCreationFormActionType.CREATE_PAYMENT_ERROR:
            return {
                ...state,
                inProgress: false,
                error: Maybe.Some(action.error),
            };
        case PaymentCreationFormActionType.TOGGLE_RECURRING_PAYMENT:
            return {
                ...state,
                inProgress: false,
                isRecurring: Maybe.Some(!state.isRecurring?.getOrElse(false)),
            };
        case PaymentCreationFormActionType.TOKEN_SUCCESS:
            return {
                ...state,
                inProgress: false,
                error: Maybe.None(),
                token: Maybe.Some(action.payload),
            };
        default:
            return state;
    }
}
