import { Maybe } from 'monet';
import { Action, ErrorAction } from '../../../redux/actions';
// import { ChargeOptions } from '@adspur-payment/commons';
import { PaymentCreationFormState } from '.';

interface CardPaymentMethod {
    type: 'card';
    card: {
        number: string;
        exp_month: number;
        exp_year: number;
        cvc: string;
    };
}

interface ChargeOptions {
    amount: number;
    currency: string;
    source: CardPaymentMethod;
    receipt_email?: string;
}

export enum PaymentCreationFormActionType {
    CREATE_PAYMENT = 'CREATE_PAYMENT',
    CREATE_PAYMENT_IN_PROGRESS = 'CREATE_PAYMENT_IN_PROGRESS',
    CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS',
    CREATE_PAYMENT_ERROR = 'CREATE_PAYMENT_ERROR',
    CREATE_PAYMENT_INTENT = 'CREATE_PAYMENT_INTENT',
    CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION',
    TOGGLE_RECURRING_PAYMENT = 'TOGGLE_RECURRING_PAYMENT',
    TOKEN_REQUEST = 'TOKEN_REQUEST',
    TOKEN_SUCCESS = 'TOKEN_SUCCESS',
    TOKEN_FAILURE = 'TOKEN_FAILURE',
}

export interface CreatePaymentAction extends Action<PaymentCreationFormActionType.CREATE_PAYMENT> {
    payment: ChargeOptions;
}

export interface CreatePaymentInProgressAction extends Action<PaymentCreationFormActionType.CREATE_PAYMENT_IN_PROGRESS> {
}

export interface CreatePaymentSuccessAction extends Action<PaymentCreationFormActionType.CREATE_PAYMENT_SUCCESS> {
    payment: boolean;
}

export interface CreatePaymentErrorAction extends ErrorAction<PaymentCreationFormActionType.CREATE_PAYMENT_ERROR> {
}

export interface ToggleRecurringPaymentAction extends Action<PaymentCreationFormActionType.TOGGLE_RECURRING_PAYMENT> {
}

export interface CreateTokenAction extends Action<PaymentCreationFormActionType.TOKEN_REQUEST> {
}

export interface CreateTokenSuccessAction extends Action<PaymentCreationFormActionType.TOKEN_SUCCESS> {
    payload: any;
}

export interface CreateTokenFailureAction extends Action<PaymentCreationFormActionType.TOKEN_FAILURE> {
    payload: any;
}

export type PaymentCreationFormAction =
    CreatePaymentAction |
    CreatePaymentInProgressAction |
    CreatePaymentSuccessAction |
    CreatePaymentErrorAction |
    ToggleRecurringPaymentAction |
    CreateTokenAction |
    CreateTokenSuccessAction |
    CreateTokenFailureAction;

export const createToken = (): PaymentCreationFormAction => ({
    type: PaymentCreationFormActionType.TOKEN_REQUEST,
});

export const createTokenSuccess = (token: any): PaymentCreationFormAction => ({
    type: PaymentCreationFormActionType.TOKEN_SUCCESS,
    payload: token,
});
  
export const createTokenFailure = (error: any): PaymentCreationFormAction => ({
    type: PaymentCreationFormActionType.TOKEN_FAILURE,
    payload: error,
});

export const toggleRecurringPayment = (): PaymentCreationFormAction => ({
    type: PaymentCreationFormActionType.TOGGLE_RECURRING_PAYMENT,
});

export const createPayment = (payment: ChargeOptions): PaymentCreationFormAction => ({
    type: PaymentCreationFormActionType.CREATE_PAYMENT,
    payment,
});

export const createPaymentInProgress = (): PaymentCreationFormAction => ({
    type: PaymentCreationFormActionType.CREATE_PAYMENT_IN_PROGRESS,
});

export const createPaymentSuccess = (payment: boolean): PaymentCreationFormAction => ({
    type: PaymentCreationFormActionType.CREATE_PAYMENT_SUCCESS,
    payment,
});

export const createPaymentError = (error: Error): PaymentCreationFormAction => ({
    type: PaymentCreationFormActionType.CREATE_PAYMENT_ERROR,
    error,
});

export const initialPaymentCreationFormState: PaymentCreationFormState = {
    inProgress: false,
    error: Maybe.None(),
    payment: Maybe.None(),
    isRecurring: Maybe.None(),
};

