import axios from "axios";
import { Dispatch } from "redux";
// import { ChargeOptions } from "@adspur-payment/commons";
import { createPaymentError, createPaymentInProgress, createPaymentSuccess, createToken, createTokenFailure, createTokenSuccess, toggleRecurringPayment } from "../redux/payment-creation-form";

interface CardPaymentMethod {
    type: 'card';
    card: {
        number: string;
        exp_month: number;
        exp_year: number;
        cvc: string;
    };
}

interface ChargeOptions {
    amount: number;
    currency: string;
    source: CardPaymentMethod;
    receipt_email?: string;
}

export const createPaymentAsync = (tokenId: any, accessToken: string, isRecurring: boolean) => (dispatch: Dispatch) => {
    dispatch(createTokenSuccess(tokenId));
    dispatch(createPaymentInProgress());
    // TODO: move to env
    if (isRecurring) {
        return axios.post('infrastructure/subscription/process', { tokenId: tokenId, accessToken: accessToken }, {
            headers: {
              'Content-Type': 'application/json'
            }
        })
        .then((response: { data: any; }) => dispatch(createPaymentSuccess(response.data)))
        .catch((error: Error) => {
            console.log(error);
            dispatch(createPaymentError(error));
            return;
        });
    } else {
        return axios.post('infrastructure/create/single/charge', { tokenId: tokenId, accessToken: accessToken }, {
            headers: {
              'Content-Type': 'application/json'
            }
        })
        .then((response: { data: any; }) => dispatch(createPaymentSuccess(response.data)))
        .catch((error: Error) => {
            console.log('error', error);
            // @ts-ignore
            return dispatch(createPaymentError(error.response.data));
        });
    }
}

export const toggleRecurringPaymentAsync = () => (dispatch: Dispatch) => {
    console.log('debbug toggleRecurringPaymentAsync')
    dispatch(toggleRecurringPayment());
}

export const createTokenRequest = () => (dispatch: Dispatch) => {
    console.log('debbug createTokenRequest');
    dispatch(createToken());
}

export const createTokenSuccessRequest = (payload: any) => (dispatch: Dispatch) => {
    console.log('debbug createTokenSuccessRequest');
    dispatch(createTokenSuccess(payload));
}

export const createTokenFailureRequest = (payload: any) => (dispatch: Dispatch) => {
    console.log('debbug createTokenFailureRequest')
    dispatch(createTokenFailure(payload));
}

export const createSuccessPayment = (payment: boolean) => (dispatch: Dispatch) => {
    console.log('debbug createSuccessPayment')
    dispatch(createPaymentSuccess(payment));
}

export const createErrorPayment = (error: Error) => (dispatch: Dispatch) => {
    console.log('debbug createErrorPayment')
    dispatch(createPaymentError(error));
}
