import { createStore, Store, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { chargeOptionsTransforms } from './components/payment/redux/payment-creation-form';

const persistConfig = {
  key: 'root',
  storage,
  transforms: [chargeOptionsTransforms()],
  blacklist: ['payment'],
  whitelist: ['user'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store: Store = createStore(persistedReducer, applyMiddleware(thunk));
export const persistor = persistStore(store);
