import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { CheckoutForm } from "./components/payment";
import "./App.css";
import config from "./config";

const stripePromise = loadStripe('pk_live_51M7hC2Dxx163WlA7cVbljVQK2TPvsA1lW8WxFwvypHmeVOVRJonN4hy65GpLaoXHaGppt4CMFqgOFDhN9mXJI2ww008DHze0Pl');

const App = () => {
  const [isValid, setIsValid] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [accessToken, setAccessToken] = useState('');

  useEffect(() => {
    console.log(window.location.search); // should log something like "?accessToken=value"
    // Obtén el token de la URL
    const urlParams = new URLSearchParams(window.location.search);
    console.log(urlParams.toString()); // should log something like "accessToken=value"
    const accessToken = urlParams.get('accessToken');
    console.log(accessToken)
    if (!accessToken) {
      setIsLoaded(true);
      return;
    }

    setAccessToken(accessToken);
    console.log('Before 4000');
    // Llama a tu backend para verificar el token
    fetch(`infrastructure/isvalid/token?accessToken=${accessToken}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        console.log('Success: ', response);
        return response.json();
      })
      .then(data => {
        console.log('success');
        setIsValid(data.data);
        setIsLoaded(true);
      }).catch(error => {
        console.log('Error: ', error);
        setIsValid(false);
      });
  }, []);

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  if (!isValid) {
    return <div>Users is not authenticated</div>;
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div className="App">
          <Elements stripe={stripePromise} options={{
          fonts: [
            {
              cssSrc:
                "https://fonts.googleapis.com/css2?family=Red+Hat+Display&display=swap"
            }
          ]
        }}>
            <CheckoutForm accessToken={accessToken} />
          </Elements>
        </div>
      </PersistGate>
    </Provider>
  );
};

export default App;
