import { combineReducers } from 'redux';
import { PaymentCreationFormState, paymentCreationFormReducer } from './components/payment/redux/payment-creation-form';
// import { userReducer } from './reducers/userReducer';

export interface RootState {
  payment: PaymentCreationFormState;
//   user: UserState;
}

export default combineReducers<RootState>({
  payment: paymentCreationFormReducer,
//   user: userReducer
});