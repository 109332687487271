import React, { useEffect, useState } from "react";
import { CardElement, PaymentElement, useStripe, useElements, ElementsConsumer, Elements } from "@stripe/react-stripe-js";
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';

import { useDispatch, connect, useSelector } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { createPaymentAsync, toggleRecurringPaymentAsync, createSuccessPayment, createErrorPayment } from "../../data";
import { RootState } from "../../../../reducers";
import { returntypeof } from "react-redux-typescript";
import config from "../../../../config";

const Component = (props: Props) => {
    const CARD_ELEMENT_OPTIONS = {
        style: {
          base: {
            color: "#1a1a1ae6",
            fontFamily: "'Red Hat Display'",
            fontSmoothing: "antialiased",
            fontSize: "18px",
            "::placeholder": {
              color: "#a0a0a0",
            }
          },
          invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
          }
        }
    };

    const success = useSelector((state: RootState) => state.payment.success);
    // @ts-ignore
    const createPaymentError = useSelector((state: RootState) => state.payment.error.getOrElse({message:''}));
    console.log("createPaymentError", createPaymentError);

    const stripe = useStripe();
    const { elements, accessToken, token, transactionID } = props;
    
    // Nuevos estados para controlar las validaciones de los campos
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [country, setCountry] = useState("");

    useEffect(() => {
        if (success) {
          const callbackUrl = `${config.services.adspurLegacyService.api.url}/payment-result?paymentStatus=Success&token=${token}&transactionID=${transactionID}`;
          window.parent.location.href = callbackUrl;
        }
    }, [success]);
  
    const handleSubmit = async (event: any) => {
      event.preventDefault();
  
      if (!stripe || !elements) return;

        if (!event.target.name.value || !event.target.address.value || !event.target.city.value || !event.target.state.value || !event.target.zip.value || !event.target.country.value) {
            console.log('Campos requeridos.');
            return;
        }

        const billingAddress = {
            name: event.target.name.value,
            line1: event.target.address.value,
            line2: event.target.apartment.value,
            city: event.target.city.value,
            state: event.target.state.value,
            postal_code: event.target.zip.value,
            country: event.target.country.value
        };
        
        const cardNumberElement = elements.getElement(CardNumberElement);
        const cardExpiryElement = elements.getElement(CardExpiryElement);
        const cardCvcElement = elements.getElement(CardCvcElement);

        // @ts-ignore
        const { token } = await stripe.createToken(cardNumberElement, { billing_details: billingAddress });

        console.log("Token created:", token?.id);

        const { error, paymentMethod } = await props.createPaymentAsync({
            tokenId: token?.id,
        }, accessToken, props.isRecurring.getOrElse(false));

        if (error) {
            console.log('[error]', error);
        } else {
            console.log('[PaymentMethod]', paymentMethod);
        }
    };
  
    const isFormValid = email !== '' && name !== '' && country !== '';

    return (
        <form onSubmit={handleSubmit}>
            <div className='form-row'>
                {/* Agregar campos para la dirección de facturación */}
                <label htmlFor="name">Name</label>
                <input type="text" id="name" name="name" required />

                <label htmlFor="card-element" className='label'>
                    Credit or debit card
                </label>

                <CardNumberElement options={CARD_ELEMENT_OPTIONS} id="cardelement" className='input' />

                <div id="expiry-cvc-element">
                    <CardExpiryElement options={CARD_ELEMENT_OPTIONS} className='input' id="expiry" />
                    <CardCvcElement options={CARD_ELEMENT_OPTIONS} className='input' id="cvc" />
                </div>

                <label htmlFor="address">Address</label>
                <input type="text" id="address" name="address" required />
    
                <label htmlFor="apartment">Apartment, suite, etc. (optional)</label>
                <input type="text" id="apartment" name="apartment" />
    
                <label htmlFor="city">City</label>
                <input type="text" id="city" name="city" required />
    
                <label htmlFor="state">State</label>
                <input type="text" id="state" name="state" required />
    
                <label htmlFor="zip">Post Code</label>
                <input type="text" id="zip" name="zip" required />
    
                <label htmlFor="country">Country</label>
                <input type="text" id="country" name="country" required />
    
                {/*<label>*/}
                {/*    <input*/}
                {/*        type="checkbox"*/}
                {/*        checked={props.isRecurring.getOrElse(false)}*/}
                {/*        onChange={() => props.toggleRecurringPaymentAsync()}*/}
                {/*    />*/}
                {/*    Subscribe to annual billing*/}
                {/*    <small>*/}
                {/*        Ticking this box sets an automatic renewal at your subscription end date.*/}
                {/*    </small>*/}
                {/*</label>*/}
            </div>
            {createPaymentError.message && (
                <div style={{color:'red'}}>
                    <p>{createPaymentError.message}</p>
                </div>
            )}

            <button type="submit" className='submit-button' disabled={props.isLoading}>
                {props.isLoading ? (
                    <span className="spinner"></span>
                ) : (
                    'Subscribe To Pro'
                )}
            </button>
            {props.success && <p>Payment succeeded!</p>}
        </form>
    );
    
    
};

// @ts-ignore
const InjectedCardForm = ({ accessToken, ...props }) => {
    return (
        <div className='container'>
            <ElementsConsumer>
            {({ stripe, elements }) => <Component {...props} stripe={stripe} elements={elements} accessToken={accessToken}/>}
            </ElementsConsumer>
        </div>
    );
};

// here we define the state of the component
const mapStateToProps = (state: RootState) => {
    return {
        payment: state.payment,
        isRecurring: state.payment.isRecurring,
        success: state.payment.success,
        transactionID: state.payment.transactionID,
        isLoading: state.payment.inProgress,
    }
};

// here we define the actions of the component
const mapDespatchToProps = (dispatch: Dispatch<any>) => bindActionCreators({
    createPaymentAsync,
    toggleRecurringPaymentAsync,
}, dispatch);


const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDespatchToProps);

//@ts-ignore
type Props = typeof stateProps & typeof dispatchProps & typeof Stripe & typeof Elements & { accessToken: string };;

export const CheckoutForm = connect<typeof stateProps, typeof dispatchProps, any>(
    // @ts-ignore
    mapStateToProps,
    mapDespatchToProps,
)(InjectedCardForm)