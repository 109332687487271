/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-underscore-dangle */

// @ts-ignore
const env = process.env;

const {
    REACT_APP_NODE_DEBUG = '*',
    REACT_APP_SERVICES_INFRASTRUCTURE_API_URL = 'http://0.0.0.0:4000',
    ABSPUR_APP_API_URLUR_APP_API_URL = 'https://adspur.com',
    ABSPUR_STRIPE_KEY = '',
    REACT_APP_VERSION = '',
} = env;

console.log(env);
console.log('----');
console.log(process.env);

export default {
    debug: REACT_APP_NODE_DEBUG,
    services: {
        infrastructure: {
            api: {
                url: REACT_APP_SERVICES_INFRASTRUCTURE_API_URL,
            },
        },
        adspurLegacyService: {
            api: {
                url: ABSPUR_APP_API_URLUR_APP_API_URL,
            }
        },
        stripe: {
            pushablekey: ABSPUR_STRIPE_KEY,
        }
    }
};
