// import { ChargeOptions } from "@adspur-payment/commons";
import { Transform, createTransform } from "redux-persist";
import { PaymentCreationFormState } from "./entities";
import { Maybe } from "monet";

interface CardPaymentMethod {
    type: 'card';
    card: {
        number: string;
        exp_month: number;
        exp_year: number;
        cvc: string;
    };
}

interface ChargeOptions {
    amount: number;
    currency: string;
    source: CardPaymentMethod;
    receipt_email?: string;
}

const ChargeCreationFromSerialize = (entity: ChargeOptions) => ({
    ...entity,
});

const ChargeCreationFromDeserialize = (entity: any): ChargeOptions => ({
    ...entity,
});


/*
* Transformations for ChargeOptions
*/
export const chargeOptionsTransforms = (): Transform<PaymentCreationFormState, any> =>
    createTransform(
        (subState, state) => ({
            ...subState,
            payment: subState.payment.map(ChargeCreationFromSerialize),
            isRecurring: subState.isRecurring?.map((value) => value).orUndefined(),
        }),
        (state, rawSate) => ({
            ...state,
            payment: Maybe.fromUndefined(state.payment).map(ChargeCreationFromDeserialize),
            isRecurring: Maybe.fromUndefined(state.isRecurring).map((value) => value),
        }),
        { whitelist: ['payment'] }
    );
