import React, { useState } from "react";
import { CardElement, useStripe, useElements, ElementsConsumer, Elements } from "@stripe/react-stripe-js";
import { useDispatch, connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { createPaymentAsync } from "../../data";
import { RootState } from "../../../../reducers";
import { returntypeof } from "react-redux-typescript";


class Component extends React.Component<Props, unknown> {
    handleSubmit = async (event: any) => {
    };

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
            </form>
        );
    }
}

// here we define the state of the component
const mapStateToProps = (state: RootState) => {
    return {
        payment: state.payment,
    }
};

// here we define the actions of the component
const mapDespatchToProps = (dispatch: Dispatch<any>) => bindActionCreators({}, dispatch);


const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDespatchToProps);

//@ts-ignore
type Props = typeof stateProps & typeof dispatchProps & typeof Stripe & typeof Elements;

export const PaymentList = connect<typeof stateProps, typeof dispatchProps, any>(
    // @ts-ignore
    mapStateToProps,
    mapDespatchToProps
)(Component)